export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/app/Banner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppCompaniesNews = () => import('../../components/app/CompaniesNews.vue' /* webpackChunkName: "components/app-companies-news" */).then(c => wrapFunctional(c.default || c))
export const AppSubscribe = () => import('../../components/app/Subscribe.vue' /* webpackChunkName: "components/app-subscribe" */).then(c => wrapFunctional(c.default || c))
export const AppTestimonials = () => import('../../components/app/Testimonials.vue' /* webpackChunkName: "components/app-testimonials" */).then(c => wrapFunctional(c.default || c))
export const AppWhyUs = () => import('../../components/app/WhyUs.vue' /* webpackChunkName: "components/app-why-us" */).then(c => wrapFunctional(c.default || c))
export const UserAdminGeneralNews = () => import('../../components/user-admin/GeneralNews.vue' /* webpackChunkName: "components/user-admin-general-news" */).then(c => wrapFunctional(c.default || c))
export const UserAdminSearch = () => import('../../components/user-admin/Search.vue' /* webpackChunkName: "components/user-admin-search" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesConsultPop = () => import('../../components/app/includes/ConsultPop.vue' /* webpackChunkName: "components/app-includes-consult-pop" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesFooter = () => import('../../components/app/includes/Footer.vue' /* webpackChunkName: "components/app-includes-footer" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesHeader = () => import('../../components/app/includes/Header.vue' /* webpackChunkName: "components/app-includes-header" */).then(c => wrapFunctional(c.default || c))
export const DashboardIncludesHeader = () => import('../../components/dashboard/includes/header.vue' /* webpackChunkName: "components/dashboard-includes-header" */).then(c => wrapFunctional(c.default || c))
export const DashboardIncludesSidebar = () => import('../../components/dashboard/includes/sidebar.vue' /* webpackChunkName: "components/dashboard-includes-sidebar" */).then(c => wrapFunctional(c.default || c))
export const GeneralBlogCard = () => import('../../components/general/blog/Card.vue' /* webpackChunkName: "components/general-blog-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsBars = () => import('../../components/general/charts/Bars.vue' /* webpackChunkName: "components/general-charts-bars" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsCircle = () => import('../../components/general/charts/Circle.vue' /* webpackChunkName: "components/general-charts-circle" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsCurves = () => import('../../components/general/charts/Curves.vue' /* webpackChunkName: "components/general-charts-curves" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsGear = () => import('../../components/general/charts/Gear.vue' /* webpackChunkName: "components/general-charts-gear" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsLines = () => import('../../components/general/charts/Lines.vue' /* webpackChunkName: "components/general-charts-lines" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsMultiPointerGear = () => import('../../components/general/charts/MultiPointerGear.vue' /* webpackChunkName: "components/general-charts-multi-pointer-gear" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsDonut = () => import('../../components/general/charts/donut.vue' /* webpackChunkName: "components/general-charts-donut" */).then(c => wrapFunctional(c.default || c))
export const GeneralClientSideMenu = () => import('../../components/general/client/SideMenu.vue' /* webpackChunkName: "components/general-client-side-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyInfoSideMenu = () => import('../../components/general/company/InfoSideMenu.vue' /* webpackChunkName: "components/general-company-info-side-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanySideMenu = () => import('../../components/general/company/SideMenu.vue' /* webpackChunkName: "components/general-company-side-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyStockSum = () => import('../../components/general/company/StockSum.vue' /* webpackChunkName: "components/general-company-stock-sum" */).then(c => wrapFunctional(c.default || c))
export const GeneralConsultList = () => import('../../components/general/consult/list.vue' /* webpackChunkName: "components/general-consult-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsColumn = () => import('../../components/general/news/Column.vue' /* webpackChunkName: "components/general-news-column" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsGrid = () => import('../../components/general/news/Grid.vue' /* webpackChunkName: "components/general-news-grid" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsActions = () => import('../../components/general/news/actions.vue' /* webpackChunkName: "components/general-news-actions" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribeCard = () => import('../../components/general/subscribe/Card.vue' /* webpackChunkName: "components/general-subscribe-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribePackages = () => import('../../components/general/subscribe/Packages.vue' /* webpackChunkName: "components/general-subscribe-packages" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribePayment = () => import('../../components/general/subscribe/Payment.vue' /* webpackChunkName: "components/general-subscribe-payment" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribeInvoice = () => import('../../components/general/subscribe/invoice.vue' /* webpackChunkName: "components/general-subscribe-invoice" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesFinancialTable = () => import('../../components/general/tables/FinancialTable.vue' /* webpackChunkName: "components/general-tables-financial-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesTable = () => import('../../components/general/tables/Table.vue' /* webpackChunkName: "components/general-tables-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralToolbarDrawerItems = () => import('../../components/general/toolbar/DrawerItems.vue' /* webpackChunkName: "components/general-toolbar-drawer-items" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiDashCardContainer = () => import('../../components/general/ui/DashCardContainer.vue' /* webpackChunkName: "components/general-ui-dash-card-container" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiDatePicker = () => import('../../components/general/ui/DatePicker.vue' /* webpackChunkName: "components/general-ui-date-picker" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiLanguageMenu = () => import('../../components/general/ui/LanguageMenu.vue' /* webpackChunkName: "components/general-ui-language-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiMiniCard = () => import('../../components/general/ui/MiniCard.vue' /* webpackChunkName: "components/general-ui-mini-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiSliderRange = () => import('../../components/general/ui/SliderRange.vue' /* webpackChunkName: "components/general-ui-slider-range" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiSvgIcon = () => import('../../components/general/ui/SvgIcon.vue' /* webpackChunkName: "components/general-ui-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiUploadImages = () => import('../../components/general/ui/uploadImages.vue' /* webpackChunkName: "components/general-ui-upload-images" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiUploadImg = () => import('../../components/general/ui/uploadImg.vue' /* webpackChunkName: "components/general-ui-upload-img" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiUploadVideo = () => import('../../components/general/ui/uploadVideo.vue' /* webpackChunkName: "components/general-ui-upload-video" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiYearMonthToggle = () => import('../../components/general/ui/yearMonthToggle.vue' /* webpackChunkName: "components/general-ui-year-month-toggle" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserForgetPassword = () => import('../../components/general/user/ForgetPassword.vue' /* webpackChunkName: "components/general-user-forget-password" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserPasswordStrength = () => import('../../components/general/user/PasswordStrength.vue' /* webpackChunkName: "components/general-user-password-strength" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserResetPassword = () => import('../../components/general/user/ResetPassword.vue' /* webpackChunkName: "components/general-user-reset-password" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserLogin = () => import('../../components/general/user/login.vue' /* webpackChunkName: "components/general-user-login" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserVerification = () => import('../../components/general/user/verification.vue' /* webpackChunkName: "components/general-user-verification" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountMenu = () => import('../../components/user-admin/account/menu.vue' /* webpackChunkName: "components/user-admin-account-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCalendarMiniCalendarCard = () => import('../../components/user-admin/calendar/MiniCalendarCard.vue' /* webpackChunkName: "components/user-admin-calendar-mini-calendar-card" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCalendarModal = () => import('../../components/user-admin/calendar/Modal.vue' /* webpackChunkName: "components/user-admin-calendar-modal" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyInfoCard = () => import('../../components/user-admin/company/InfoCard.vue' /* webpackChunkName: "components/user-admin-company-info-card" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyInfoText = () => import('../../components/user-admin/company/InfoText.vue' /* webpackChunkName: "components/user-admin-company-info-text" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyMenu = () => import('../../components/user-admin/company/Menu.vue' /* webpackChunkName: "components/user-admin-company-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyNews = () => import('../../components/user-admin/company/News.vue' /* webpackChunkName: "components/user-admin-company-news" */).then(c => wrapFunctional(c.default || c))
export const UserAdminIncludesFooter = () => import('../../components/user-admin/includes/Footer.vue' /* webpackChunkName: "components/user-admin-includes-footer" */).then(c => wrapFunctional(c.default || c))
export const UserAdminIncludesHeader = () => import('../../components/user-admin/includes/Header.vue' /* webpackChunkName: "components/user-admin-includes-header" */).then(c => wrapFunctional(c.default || c))
export const UserAdminIncludesSidebar = () => import('../../components/user-admin/includes/Sidebar.vue' /* webpackChunkName: "components/user-admin-includes-sidebar" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserDropdownMenu = () => import('../../components/user-admin/user/DropdownMenu.vue' /* webpackChunkName: "components/user-admin-user-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserMenu = () => import('../../components/user-admin/user/Menu.vue' /* webpackChunkName: "components/user-admin-user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserNotifications = () => import('../../components/user-admin/user/Notifications.vue' /* webpackChunkName: "components/user-admin-user-notifications" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsAdminsPermissionsForm = () => import('../../components/dashboard/forms/admins/PermissionsForm.vue' /* webpackChunkName: "components/dashboard-forms-admins-permissions-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsAdminsUsersForm = () => import('../../components/dashboard/forms/admins/UsersForm.vue' /* webpackChunkName: "components/dashboard-forms-admins-users-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCalendarInfoForm = () => import('../../components/dashboard/forms/calendar/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-calendar-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCategoriesInfoForm = () => import('../../components/dashboard/forms/categories/InfoForm.vue' /* webpackChunkName: "components/dashboard-forms-categories-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsClientInfoForm = () => import('../../components/dashboard/forms/client/InfoForm.vue' /* webpackChunkName: "components/dashboard-forms-client-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsClientPlansForm = () => import('../../components/dashboard/forms/client/plansForm.vue' /* webpackChunkName: "components/dashboard-forms-client-plans-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCompanyInfoForm = () => import('../../components/dashboard/forms/company/InfoForm.vue' /* webpackChunkName: "components/dashboard-forms-company-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCompanySubscriptionInfoForm = () => import('../../components/dashboard/forms/companySubscription/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-company-subscription-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCouponsInfoForm = () => import('../../components/dashboard/forms/coupons/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-coupons-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCouponsStatisticsForm = () => import('../../components/dashboard/forms/coupons/statisticsForm.vue' /* webpackChunkName: "components/dashboard-forms-coupons-statistics-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsFeaturesForm = () => import('../../components/dashboard/forms/features/Form.vue' /* webpackChunkName: "components/dashboard-forms-features-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsIndicatorsInfoForm = () => import('../../components/dashboard/forms/indicators/InfoForm.vue' /* webpackChunkName: "components/dashboard-forms-indicators-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsMainMarketPricesInfoForm = () => import('../../components/dashboard/forms/mainMarketPrices/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-main-market-prices-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsMarketInfoSumForm = () => import('../../components/dashboard/forms/marketInfoSum/form.vue' /* webpackChunkName: "components/dashboard-forms-market-info-sum-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsNewsInfoForm = () => import('../../components/dashboard/forms/news/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-news-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsPagesInfoForm = () => import('../../components/dashboard/forms/pages/infoForm.vue' /* webpackChunkName: "components/dashboard-forms-pages-info-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsPlansForm = () => import('../../components/dashboard/forms/plans/Form.vue' /* webpackChunkName: "components/dashboard-forms-plans-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsReviewsForm = () => import('../../components/dashboard/forms/reviews/Form.vue' /* webpackChunkName: "components/dashboard-forms-reviews-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsSectorsInfoForm = () => import('../../components/dashboard/forms/sectors/InfoForm.vue' /* webpackChunkName: "components/dashboard-forms-sectors-info-form" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsCachCharts = () => import('../../components/general/company/tabs/CachCharts.vue' /* webpackChunkName: "components/general-company-tabs-cach-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsComparison = () => import('../../components/general/company/tabs/Comparison.vue' /* webpackChunkName: "components/general-company-tabs-comparison" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsEarnDates = () => import('../../components/general/company/tabs/EarnDates.vue' /* webpackChunkName: "components/general-company-tabs-earn-dates" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsFinancialList = () => import('../../components/general/company/tabs/FinancialList.vue' /* webpackChunkName: "components/general-company-tabs-financial-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsProfile = () => import('../../components/general/company/tabs/Profile.vue' /* webpackChunkName: "components/general-company-tabs-profile" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsProfitCharts = () => import('../../components/general/company/tabs/ProfitCharts.vue' /* webpackChunkName: "components/general-company-tabs-profit-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsQualityCharts = () => import('../../components/general/company/tabs/QualityCharts.vue' /* webpackChunkName: "components/general-company-tabs-quality-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsValueCharts = () => import('../../components/general/company/tabs/ValueCharts.vue' /* webpackChunkName: "components/general-company-tabs-value-charts" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsPassword = () => import('../../components/user-admin/account/tabs/Password.vue' /* webpackChunkName: "components/user-admin-account-tabs-password" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsProfile = () => import('../../components/user-admin/account/tabs/Profile.vue' /* webpackChunkName: "components/user-admin-account-tabs-profile" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsSettings = () => import('../../components/user-admin/account/tabs/Settings.vue' /* webpackChunkName: "components/user-admin-account-tabs-settings" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsSubscriptions = () => import('../../components/user-admin/account/tabs/Subscriptions.vue' /* webpackChunkName: "components/user-admin-account-tabs-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsSectorsItemsItemForm = () => import('../../components/dashboard/forms/sectors/Items/ItemForm.vue' /* webpackChunkName: "components/dashboard-forms-sectors-items-item-form" */).then(c => wrapFunctional(c.default || c))
export const DashboardFormsCompanyFinancialFileItemsDetailsItemDetailsForm = () => import('../../components/dashboard/forms/company/financialFile/ItemsDetails/ItemDetailsForm.vue' /* webpackChunkName: "components/dashboard-forms-company-financial-file-items-details-item-details-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
